
export const LOCAL_STORAGE_KEYS = {
  SELECTED_ROLE: 'selectedRole',
  SESSION_ID: 'sessionId',
  THEME: 'theme',
  OFFLINE_USER: 'offline_user',
  OFFLINE_NEWS: 'offline_news',
  BEFORE_AUTH_REFERER: 'before_auth_referer',
};

export const COOKIE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  ID_TOKEN: 'id_token',
};
