import cookieStorage from 'store/storages/cookieStorage';
import localStorage from 'store/storages/localStorage';
import engine from 'store/src/store-engine';
import { COOKIE_KEYS, LOCAL_STORAGE_KEYS } from './constants';

class _localStore {
  constructor() {
    this.store = engine.createStore(localStorage);
  }

  remove(key) {
    this.store.remove(key);
  }

  set(key, value) {
    this.store.set(key, value);
  }

  get(key) {
    return this.store.get(key);
  }
}

export const localStore = new _localStore();

export class _cookieStore {
  constructor() {
    this.store = engine.createStore(cookieStorage);
  }

  get(key) {
    return this.store.get(key);
  }

  set(key, value) {
    this.store.set(key, value);
  }

  delete(name) {
    document.cookie = `${name}=;domain=.irdeto.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  saveAccessToken(accessToken) {
    document.cookie = `${COOKIE_KEYS.ACCESS_TOKEN}=${accessToken};domain=.irdeto.com;path=/`;
  }

  saveIdToken(idToken) {
    document.cookie = `${COOKIE_KEYS.ID_TOKEN}=${idToken};domain=.irdeto.com;path=/`;
  }
}

export const cookieStore = new _cookieStore();

export const storeBeforeAuthReferer = (referer) => {
  localStore.set(LOCAL_STORAGE_KEYS.BEFORE_AUTH_REFERER, referer);
};

export const removeBeforeAuthReferer = () => {
  const referer = localStore.get(LOCAL_STORAGE_KEYS.BEFORE_AUTH_REFERER);
  localStore.remove(LOCAL_STORAGE_KEYS.BEFORE_AUTH_REFERER);
  return referer;
};
