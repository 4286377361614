import _ from 'lodash';
import ACL from 'acl';

import { LOCAL_STORAGE_KEYS } from '../libs/constants';
import { localStore } from './storage';


const toValue = (status, structObj) => structObj[_.toUpper(status)];
const enumFunctions = (value1, values) => {
  console.warn('Function is deprecated!'); // eslint-disable-line
  return ({
    eq: value2 => toValue(value1, values) === toValue(value2, values),
    isValid: () => !!toValue(value1, values),
    value: () => toValue(value1, values),
  });
};

// ROLES
const [ADMIN, ANALYST, TENANT] = ['admin', 'analyst', 'tenant'];
export { ADMIN, ANALYST, TENANT };

export const ROLES = ['admin', 'analyst', 'tenant'];

export const ROLE = (role1) => ({
  ...enumFunctions(role1, { ADMIN, ANALYST, TENANT }),
});

export const getDefaultRole = (privileges, viewingAs) => {
  if (!privileges) {
    // In auth0 redirects, role will not be used
    return null;
  }

  const acl = new ACL(privileges);
  if (viewingAs && acl.role(viewingAs).can()) {
    return viewingAs;
  }

  const selectedRole = localStore.get(LOCAL_STORAGE_KEYS.SELECTED_ROLE);
  if (selectedRole && acl.role(selectedRole).can()) {
    return selectedRole;
  }
  // Could not find suitable role for user, just return the first role user has
  return _(acl.byRole()).keys().first();
};
